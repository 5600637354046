function setDeepTabIndexHidden(hide, rootElement) {
    const exceptionAttribute = '[data-prevent-tab-manipulation]';
    const focusableElements = rootElement.querySelectorAll(`a:not(${ exceptionAttribute }), button:not(${ exceptionAttribute }), input:not(${ exceptionAttribute }), textarea:not(${ exceptionAttribute }), select:not(${ exceptionAttribute }), details:not(${ exceptionAttribute })`);
    focusableElements.forEach(focusableElement => {
        if (hide) {
            focusableElement.setAttribute('tabindex', '-1');
            focusableElement.setAttribute('aria-hidden', true);
            focusableElement.setAttribute('inert', true);
        } else {
            focusableElement.removeAttribute('tabindex');
            focusableElement.removeAttribute('aria-hidden');
            focusableElement.removeAttribute('inert');
        }
    });
}

export { setDeepTabIndexHidden };
