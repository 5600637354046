import { arrayFromIterables } from 'tools/arrays';
import { setDeepTabIndexHidden } from 'tools/setDeepTabIndexHidden';

const isDesktop = () => {
    return (
        window.matchMedia('(min-width: 1200px)').matches
    );
};

const supportCentersRoot = document.querySelector('[data-supportcenter-root]');
if (isDesktop() && supportCentersRoot) {
    const supportCenters = supportCentersRoot.querySelectorAll('[data-supportcenter]');
    supportCenters.forEach((supportCenterElement, index) => {
        const supportCenter = supportCenterElement.dataset.supportcenter;
        const contactCardsItems = supportCentersRoot.querySelectorAll('[data-supportcentercard]');
        const contactCard = arrayFromIterables(contactCardsItems).find(contactCardsItem => contactCardsItem.dataset.supportcentercard === supportCenter);
        const contactCardsIndex = arrayFromIterables(contactCard.parentNode.children).indexOf(contactCard);
        const totalCards = contactCardsItems.length;
        if (index === 0) {
            setDeepTabIndexHidden(false, contactCard);
        } else {
            setDeepTabIndexHidden(true, contactCard);
        }
        supportCenterElement.addEventListener('click', event => {
            const eventTarget = event.currentTarget;
            supportCenters.forEach(supportCenter => {
                supportCenter.classList.remove('supportCenters__item--active');
                supportCenter.setAttribute('aria-selected', 'false');
            });
            eventTarget.classList.add('supportCenters__item--active');
            eventTarget.setAttribute('aria-selected', 'true');
            contactCardsItems.forEach((contactCardsItem, index) => {
                for (let i = 0; i < totalCards; i++) {
                    contactCardsItem.classList.remove(`contactCards__item--${ i + 1 }`);
                }
                const dif = totalCards - contactCardsIndex;
                const newIndex = ((index + dif) % totalCards);
                contactCardsItem.classList.add(`contactCards__item--${ newIndex + 1 }`);
                if (index === contactCardsIndex) {
                    setDeepTabIndexHidden(false, contactCardsItem);
                } else {
                    setDeepTabIndexHidden(true, contactCardsItem);
                }
            });
        });
    });
}
