import { isIE } from 'tools/isUserAgent';

function arrayFromIterables(iterable) {
    // disabling Symbol check in IE because it doesn't support the data type Symbol
    if (!isIE() && typeof iterable[Symbol.iterator] !== 'function') {
        throw new TypeError('Parameter is not iterable.');
    }
    const newArray = [];
    for (let i = 0, length = iterable.length; i < length; i++) {
        newArray.push(iterable[i]);
    }

    return newArray;
}

export { arrayFromIterables };
